@import '../functions/functions';

$black: #232323;
$blue: #1caac5;
$green: #32bf73;
$red: #ec5a58;
$yellow: #ffb102;
$white: #ffffff;
$grey: #999999;
$greyDark: #666666;
$greyLight: #f6f6f6;
$borderGrey: #e1e1e1;

:root {
  --taia-color-red: #{$red};
  --taia-color-blue: #{$blue};
  --taia-color-green: #{$green};
}

$colors: (
  'blue': (
    'primary': $blue,
    'light-1': scale-color($blue, $lightness: 20%),
    'light-2': scale-color($blue, $lightness: 40%),
    'light-3': scale-color($blue, $lightness: 60%),
    'light-4': scale-color($blue, $lightness: 80%),
  ),
  'green': (
    'primary': $green,
    'light-1': scale-color($green, $lightness: 20%),
    'light-2': scale-color($green, $lightness: 40%),
    'light-3': scale-color($green, $lightness: 60%),
    'light-4': scale-color($green, $lightness: 80%),
  ),
  'yellow': (
    'primary': $yellow,
    'light-1': scale-color($yellow, $lightness: 20%),
    'light-2': scale-color($yellow, $lightness: 40%),
    'light-3': scale-color($yellow, $lightness: 60%),
    'light-4': scale-color($yellow, $lightness: 80%),
  ),
  'red': (
    'primary': $red,
    'light-1': scale-color($red, $lightness: 20%),
    'light-2': scale-color($red, $lightness: 40%),
    'light-3': scale-color($red, $lightness: 60%),
    'light-4': scale-color($red, $lightness: 80%),
  ),
  'black': (
    'primary': $black,
    'light-1': scale-color($black, $lightness: 20%),
    'light-2': scale-color($black, $lightness: 40%),
    'light-3': scale-color($black, $lightness: 60%),
    'light-4': scale-color($black, $lightness: 80%),
  ),
  'grey': (
    'primary': $grey,
    'light-1': scale-color($grey, $lightness: 20%),
    'light-2': scale-color($grey, $lightness: 40%),
    'light-3': scale-color($grey, $lightness: 60%),
    'light-4': scale-color($grey, $lightness: 80%),
    'light-45': scale-color($grey, $lightness: 90%),
  ),
);

$blue-primary: map-deep-get($colors, 'blue', 'primary');
$blue-light-1: map-deep-get($colors, 'blue', 'light-1');
$blue-light-2: map-deep-get($colors, 'blue', 'light-2');
$blue-light-3: map-deep-get($colors, 'blue', 'light-3');
$blue-light-4: map-deep-get($colors, 'blue', 'light-4');

$green-primary: map-deep-get($colors, 'green', 'primary');
$green-light-1: map-deep-get($colors, 'green', 'light-1');
$green-light-2: map-deep-get($colors, 'green', 'light-2');
$green-light-3: map-deep-get($colors, 'green', 'light-3');
$green-light-4: map-deep-get($colors, 'green', 'light-4');

$yellow-primary: map-deep-get($colors, 'yellow', 'primary');
$yellow-light-1: map-deep-get($colors, 'yellow', 'light-1');
$yellow-light-2: map-deep-get($colors, 'yellow', 'light-2');
$yellow-light-3: map-deep-get($colors, 'yellow', 'light-3');
$yellow-light-4: map-deep-get($colors, 'yellow', 'light-4');

$red-primary: map-deep-get($colors, 'red', 'primary');
$red-light-1: map-deep-get($colors, 'red', 'light-1');
$red-light-2: map-deep-get($colors, 'red', 'light-2');
$red-light-3: map-deep-get($colors, 'red', 'light-3');
$red-light-4: map-deep-get($colors, 'red', 'light-4');

$black-primary: map-deep-get($colors, 'black', 'primary');
$black-light-1: map-deep-get($colors, 'black', 'light-1');
$black-light-2: map-deep-get($colors, 'black', 'light-2');
$black-light-3: map-deep-get($colors, 'black', 'light-3');
$black-light-4: map-deep-get($colors, 'black', 'light-4');

$grey-primary: map-deep-get($colors, 'grey', 'primary');
$grey-light-1: map-deep-get($colors, 'grey', 'light-1');
$grey-light-2: map-deep-get($colors, 'grey', 'light-2');
$grey-light-3: map-deep-get($colors, 'grey', 'light-3');
$grey-light-4: map-deep-get($colors, 'grey', 'light-4');

:export {
  @each $key, $value in $colors {
    #{unquote($key)}: $key;
  }
}
