@import 'style/variables/_variables.scss';
@import 'style/mixins/_breakpoints.scss';

.support-content {
  // Local variables
  $card-width: 273px;
  $card-gap: 30px;

  > h1 {
    font-size: 2.14rem;
    font-weight: normal;
    margin-bottom: 28px;
  }

  &__sections {
    background-color: $white;
    padding: 90px 58px 70px 58px;
    border: solid 1px #e9e9e9;

    > :not(:last-child) {
      margin-bottom: 62px;
    }

    > section {
      // 3 cards max width
      max-width: #{($card-width * 3) + ($card-gap * 2)};
      margin: 0 auto;

      > h2 {
        font-size: 2.57rem;
        font-weight: normal;
        text-align: center;
        max-width: 640px;
        margin: 0 auto;
        margin-bottom: 62px;
      }

      > ul {
        padding: 0;
        margin: 0;
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(auto-fit, minmax(#{$card-width}, 1fr));
        list-style-type: none;

        &.categories-list {
          gap: 60px 30px;
        }
      }

      > hr {
        width: 100%;
        max-width: 881px;
        height: 1px;
        opacity: 0.1;
        background-color: #000;
        margin-top: 60px;
      }
    }

    @include media-breakpoint-down(mobile) {
      padding: 50px 20px;

      > section {
        &.support-content__sections__hints-tips {
          > h2 {
            margin-bottom: 90px;
          }
        }

        > ul.categories-list {
          gap: 90px 30px;
        }
      }
    }
  }
}

@include media-breakpoint-down(mobile) {
  main.support-content {
    padding: 0;
  }
}
