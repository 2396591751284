// Padding variables

// Main container
$main-container-x: 50px;
$main-container-y: 80px;
$main-container-full: $main-container-y $main-container-x;
$main-container-mobile-x: 20px;
$main-container-mobile-y: 0;
$main-container-mobile-full: $main-container-mobile-y $main-container-mobile-x;

:export {
  mainContainerX: $main-container-x;
  mainContainerY: $main-container-y;
  mainContainerFull: $main-container-full;
  mainContainerMobileX: $main-container-mobile-x;
  mainContainerMobileY: $main-container-mobile-y;
  mainContainerMobileFull: $main-container-mobile-full;
}
