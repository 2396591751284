@mixin button-reset($colorReset: true) {
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;

  @if $colorReset {
    color: inherit;
    background-color: transparent;
  }
}
