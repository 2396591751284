// Flexbox
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}
