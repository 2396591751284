@import 'style/variables/_variables.scss';

// sets text color to dark or light depending on the contrast with background color passed
@mixin get-text-color($background-color) {
  $color-brightness: round(
    (red($background-color) * 299) + (green($background-color) * 587) + (blue($background-color) * 114) / 1000
  );
  $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + (blue(#ffffff) * 114) / 1000);

  @if abs($color-brightness) < ($light-color/2) {
    color: map-get($font-colors, 'light');
  } @else {
    color: map-get($font-colors, 'dark');
  }
}
