.filters-checkbox-select-all {
  border-bottom: 1px solid rgba($color: $black, $alpha: 0.3);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.hero-img {
  width: 436px;
  height: 350px;

  @media (max-width: 436px) {
    width: 100%;
    height: auto;
    min-height: 250px;
  }
}
