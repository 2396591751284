// All global font classes should go here
@import 'style/variables/_variables.scss';

.label {
  font-size: map-get($font-sizes, '6');
  line-height: map-get($line-height-sizes, '6');
  margin-bottom: map-get($spacing-sizes, '6');
  max-width: $max-text-width;
}

.grey-label {
  background-color: #edeeec;
  padding: 0.5rem 1.2rem;
  font-size: map-get($font-sizes, '6');
  width: fit-content;
}

.color-label {
  --box-bg-color: #{$blue};

  display: flex;
  align-items: center;

  // The left and right
  > span:not(.icon) {
    height: 16px;
    width: 16px;
    display: block;
    margin-right: 8px;
    background-color: var(--box-bg-color);
    border-radius: 4px;
  }

  > .icon {
    color: $blue;
    cursor: pointer;
  }

  > p {
    margin: 0;

    > strong {
      font-weight: bold;
      color: $black;
    }
  }
}

.body-text {
  font-size: map-get($font-sizes, '5');
  line-height: map-get($line-height-sizes, '5');
  margin-bottom: map-get($spacing-sizes, '5');
  max-width: $max-text-width;
}

// Truncated text
.truncated-text {
  --max-width: 15ch;

  display: block;
  max-width: var(--max-width);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// !important is here to override semantic
@each $number, $font-size in $font-sizes {
  .heading-#{$number} {
    font-size: $font-size !important;
    font-weight: map-get($font-weights, 'normal') !important;
    max-width: $max-text-width;
    padding: 0 !important;
  }
}

// !important is here to override semantic
@each $number, $line-height in $line-height-sizes {
  .heading-#{$number} {
    line-height: $line-height !important;
  }
}

// !important is here to override semantic
@each $number, $margin in $spacing-sizes {
  .heading-#{$number} {
    &.strong {
      font-weight: bold !important;
    }
    margin: 0 !important;
    margin-bottom: $margin !important;
  }
  .margin-#{$number} {
    margin: $margin;
  }
}
