// Texts
.body-text-red {
  color: $red !important;
}

.body-text-border-grey {
  color: $borderGrey !important;
}

// Hover
.hover {
  &\:bg-red-4:hover {
    background-color: map-deep-get($colors, 'red', 'light-4') !important;
  }
}

@each $colName, $varients in $colors {
  @each $varient, $color in $varients {
    .has-text-#{$colName}-#{$varient} {
      color: $color;
    }
  }
}

// Background color - hover
@each $colName, $varients in $colors {
  @each $varient, $color in $varients {
    .hover\:has-bg-#{$colName}-#{$varient}:hover {
      background-color: $color;
    }
  }
}
