@import 'style/_imports.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  margin-top: map-get($spacing-sizes, '1');
}

.text.last-text {
  margin-bottom: map-get($spacing-sizes, '2');
}
