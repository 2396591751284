$font-family: 'DM Sans', sans-serif;

// Base font size defines the font-size of the html elrement. Chaging this should scale the rest of the elrements for the whole app.
$base-font-size: 14px;
$label-size: 0.786rem;
$max-text-width: 60ch;

// Bold is used to distinguish important info in a sentence/table etc.
$font-weights: (
  'normal': 400,
  'bold': 500,
);

// 1 is the biggest heading, 5 is for base fonts and 6 is for labels
$font-sizes: (
  '1': 2.643rem,
  '2': 2.071rem,
  '3': 1.643rem,
  '4': 1.286rem,
  '5': 1rem,
  '6': 0.886rem,
);

$line-height-sizes: (
  '1': 1.5,
  '2': 1.621,
  '3': 1.696,
  '4': 1.722,
  '5': 1.857,
  '6': 1.909,
);

// These should also be used for spacing between different elrements on the page. They are inside font variables since they are relative to the base font-size
$spacing-sizes: (
  '1': 3rem,
  '2': 1.857rem,
  '3': 1.143rem,
  '4': 0.714rem,
  '5': 0.429rem,
  '6': 0.286rem,
);
