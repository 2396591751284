@import 'style/_imports.scss';

.error-screen {
  p {
    text-align: justify;
  }

  img {
    width: 90%;
    max-width: 450px;
  }

  .error-frame {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid $red;
    background-color: $red-light-4;
    color: $red;
    margin-bottom: 20px;
  }
}
