.person-filter-dropdown.multiple {
  .multiple-dropdown-selected {
    .user-avatar {
      height: 1rem;
      width: 1rem;
    }
  }

  .dropdown-header {
    background-image: none;
  }

  .dropdown-item {
    .user-avatar {
      height: 2rem;
      width: 2rem;
    }
  }
}
