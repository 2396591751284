// global style definitions for the whole app here

@import 'style/_imports.scss';

// !important is here to override semantic
html,
body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

html,
body {
  color: map-get($font-colors, 'dark');
  font-family: $font-family;
  font-size: $base-font-size;
  font-weight: map-get($font-weights, 'normal');
  position: fixed;
}

body.is-safari-ios {
  input,
  textarea {
    font-size: 16px;
  }
}

// TODO should probably moved to the captcha component
.grecaptcha-badge {
  visibility: hidden;
}

// TODO check why this is here at all
@media only screen and (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
